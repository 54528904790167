<template>
  <b-modal id="modal-select-painel" hide-footer size="lg">
    <div class="spaceModal">
      <div class="titleModal">Meus Painéis</div>
      <div class="header" v-if="!isStudent">
        <button class="newPainel" @click="openNew">Novo Painel</button>
      </div>
      <section class="panels" :class="{ 'mt-4': isStudent }">
        <section
          class="panel"
          v-for="painel in Painels"
          :key="painel.id"
          @click="selectedPainel(painel)"
        >
          <section>
            <span
              v-if="painel.logo === null"
              class="noLogo"
            />
            <img v-else :src="painel.logo" alt="logo" class="logo" />
            <h6 class="title">{{ painel.name }}</h6>
          </section>
          <img
            class="arrow-img"
            src="@/assets/icons/arrow-gray.svg"
            alt="arrow right img"
            @click="selectedPainel(painel)"
          />
        </section>
      </section>
    </div>
  </b-modal>
</template>
<script>
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
  components: {},
  data() {
    return {
      Painels: [],
      permission: false,
    };
  },
  computed: {
    isStudent() {
      if(this.$store.getters.currentSite.currentMember.role) {
        return this.$store.getters.currentSite.currentMember.role === 'student';
      } else {
        return false;
      }
    }
  },
  methods: {
    openNew() {
      this.$root.$emit("bv::hide::modal", "modal-select-painel", "#btnShow");
      this.$root.$emit("bv::show::modal", "modal-new-painel", "#btnShow");
    },
    selectedPainel(data) {
      this.$store.dispatch("blogSelected", data);
    },
    getPermission() {
      let permission = Cookies.get("access_level");
      if (permission === "admin" || permission === "owner") {
        this.permission = true;
      } else {
        this.permission = false;
      }
    },
    getPainel() {
      // let data = Cookies.get('blogsClub');
      // if(data === undefined || data === '' || data === null){
      //     this.Painels = data;
      // }else{
      //     this.Painels = JSON.parse(data);
      // }
      serviceMember
        .read("me")
        .then((resp) => {
          //console.log("get members", resp);
          Cookies.set("memberAuth", resp.member.Auth, { expires: 365 });
          // Cookies.set('blogsClub', resp.member.sites, { expires: 365 });
          this.Painels = resp.member.sites;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
  mounted() {
    this.getPainel();
    this.getPermission();
    this.$root.$on("createsite", (data) => {
      this.Painels = data;
      Cookies.set("blogsClub", data, { expires: 365 });
    });
    this.$root.$on("getAllPainels", (data) => {
      this.getPainel();
      this.getPermission();
    });
  },
};
</script>
<style lang="scss">
#modal-select-painel {
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 25px;
    margin-top: -35px;
    margin-bottom: 20px;
    width: max-content;
  }
  .modal-header {
    border: none !important;
  }
  .spaceModal {
    padding: 5px ​30px 0px 30px;
    .logout {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }
    .logout2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }
    .titleModal {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }

    .panels {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      height: 100%;

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      .panel {
        width: 100%;
        height: 70px;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: rgba($color: #f7f7f7, $alpha: 0.4);
        cursor: pointer;
        transition: 200ms ease-in-out;

        section {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .logo {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }

        .noLogo {
            width: 40px;
            height: 40px;
            background: #c9c9c9;
            border-radius: 3px;
        }

        .title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor);
          margin-left: 20px;
          line-height: 40px;
        }

        .arrow-img {
          transform: rotate(-90deg);
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
